import { Readable } from "stream";
import { Buffer } from "buffer";
import { getChunkBuffer } from "./chunks/getChunkBuffer";
import { getChunkStream } from "./chunks/getChunkStream";
import { getDataReadableStream } from "./chunks/getDataReadableStream";
import { getDataReadable } from "./chunks/getDataReadable";
export var getChunk = function (data, partSize) {
    if (data instanceof Buffer) {
        return getChunkBuffer(data, partSize);
    }
    else if (data instanceof Readable) {
        return getChunkStream(data, partSize, getDataReadable);
    }
    else if (data instanceof String || typeof data === "string" || data instanceof Uint8Array) {
        return getChunkBuffer(Buffer.from(data), partSize);
    }
    if (typeof data.stream === "function") {
        return getChunkStream(data.stream(), partSize, getDataReadableStream);
    }
    else if (data instanceof ReadableStream) {
        return getChunkStream(data, partSize, getDataReadableStream);
    }
    else {
        throw new Error("Body Data is unsupported format, expected data to be one of: string | Uint8Array | Buffer | Readable | ReadableStream | Blob;.");
    }
};
