import { __assign, __awaiter, __generator } from "tslib";
export function locationConstraintMiddleware(options) {
    var _this = this;
    return function (next) {
        return function (args) { return __awaiter(_this, void 0, void 0, function () {
            var CreateBucketConfiguration, region;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        CreateBucketConfiguration = args.input.CreateBucketConfiguration;
                        return [4, options.region()];
                    case 1:
                        region = _a.sent();
                        if (!CreateBucketConfiguration || !CreateBucketConfiguration.LocationConstraint) {
                            args = __assign(__assign({}, args), { input: __assign(__assign({}, args.input), { CreateBucketConfiguration: region === "us-east-1" ? undefined : { LocationConstraint: region } }) });
                        }
                        return [2, next(args)];
                }
            });
        }); };
    };
}
export var locationConstraintMiddlewareOptions = {
    step: "initialize",
    tags: ["LOCATION_CONSTRAINT", "CREATE_BUCKET_CONFIGURATION"],
    name: "locationConstraintMiddleware",
    override: true,
};
export var getLocationConstraintPlugin = function (config) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(locationConstraintMiddleware(config), locationConstraintMiddlewareOptions);
    },
}); };
