import { __asyncGenerator, __asyncValues, __await, __generator } from "tslib";
import { Buffer } from "buffer";
export function getChunkStream(data, partSize, getNextData) {
    return __asyncGenerator(this, arguments, function getChunkStream_1() {
        var partNumber, currentBuffer, _a, _b, datum, dataChunk, e_1_1;
        var e_1, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    partNumber = 1;
                    currentBuffer = { chunks: [], length: 0 };
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 9, 10, 15]);
                    _a = __asyncValues(getNextData(data));
                    _d.label = 2;
                case 2: return [4, __await(_a.next())];
                case 3:
                    if (!(_b = _d.sent(), !_b.done)) return [3, 8];
                    datum = _b.value;
                    currentBuffer.chunks.push(datum);
                    currentBuffer.length += datum.length;
                    _d.label = 4;
                case 4:
                    if (!(currentBuffer.length >= partSize)) return [3, 7];
                    dataChunk = currentBuffer.chunks.length > 1 ? Buffer.concat(currentBuffer.chunks) : currentBuffer.chunks[0];
                    return [4, __await({
                            partNumber: partNumber,
                            data: dataChunk.slice(0, partSize),
                        })];
                case 5: return [4, _d.sent()];
                case 6:
                    _d.sent();
                    currentBuffer.chunks = [dataChunk.slice(partSize)];
                    currentBuffer.length = currentBuffer.chunks[0].length;
                    partNumber += 1;
                    return [3, 4];
                case 7: return [3, 2];
                case 8: return [3, 15];
                case 9:
                    e_1_1 = _d.sent();
                    e_1 = { error: e_1_1 };
                    return [3, 15];
                case 10:
                    _d.trys.push([10, , 13, 14]);
                    if (!(_b && !_b.done && (_c = _a.return))) return [3, 12];
                    return [4, __await(_c.call(_a))];
                case 11:
                    _d.sent();
                    _d.label = 12;
                case 12: return [3, 14];
                case 13:
                    if (e_1) throw e_1.error;
                    return [7];
                case 14: return [7];
                case 15: return [4, __await({
                        partNumber: partNumber,
                        data: Buffer.concat(currentBuffer.chunks),
                        lastPart: true,
                    })];
                case 16: return [4, _d.sent()];
                case 17:
                    _d.sent();
                    return [2];
            }
        });
    });
}
