import { __assign } from "tslib";
export function resolveBucketEndpointConfig(input) {
    var _a = input.bucketEndpoint, bucketEndpoint = _a === void 0 ? false : _a, _b = input.forcePathStyle, forcePathStyle = _b === void 0 ? false : _b, _c = input.useAccelerateEndpoint, useAccelerateEndpoint = _c === void 0 ? false : _c, _d = input.useDualstackEndpoint, useDualstackEndpoint = _d === void 0 ? false : _d, _e = input.useArnRegion, useArnRegion = _e === void 0 ? false : _e, _f = input.disableMultiregionAccessPoints, disableMultiregionAccessPoints = _f === void 0 ? false : _f;
    return __assign(__assign({}, input), { bucketEndpoint: bucketEndpoint, forcePathStyle: forcePathStyle, useAccelerateEndpoint: useAccelerateEndpoint, useDualstackEndpoint: useDualstackEndpoint, useArnRegion: typeof useArnRegion === "function" ? useArnRegion : function () { return Promise.resolve(useArnRegion); }, disableMultiregionAccessPoints: typeof disableMultiregionAccessPoints === "function"
            ? disableMultiregionAccessPoints
            : function () { return Promise.resolve(disableMultiregionAccessPoints); } });
}
export var NODE_USE_ARN_REGION_ENV_NAME = "AWS_S3_USE_ARN_REGION";
export var NODE_USE_ARN_REGION_INI_NAME = "s3_use_arn_region";
export var NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME = "AWS_S3_DISABLE_MULTIREGION_ACCESS_POINTS";
export var NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME = "s3_disable_multiregion_access_points";
export var NODE_USE_ARN_REGION_CONFIG_OPTIONS = {
    environmentVariableSelector: function (env) {
        if (!Object.prototype.hasOwnProperty.call(env, NODE_USE_ARN_REGION_ENV_NAME))
            return undefined;
        if (env[NODE_USE_ARN_REGION_ENV_NAME] === "true")
            return true;
        if (env[NODE_USE_ARN_REGION_ENV_NAME] === "false")
            return false;
        throw new Error("Cannot load env " + NODE_USE_ARN_REGION_ENV_NAME + ". Expected \"true\" or \"false\", got " + env[NODE_USE_ARN_REGION_ENV_NAME] + ".");
    },
    configFileSelector: function (profile) {
        if (!Object.prototype.hasOwnProperty.call(profile, NODE_USE_ARN_REGION_INI_NAME))
            return undefined;
        if (profile[NODE_USE_ARN_REGION_INI_NAME] === "true")
            return true;
        if (profile[NODE_USE_ARN_REGION_INI_NAME] === "false")
            return false;
        throw new Error("Cannot load shared config entry " + NODE_USE_ARN_REGION_INI_NAME + ". Expected \"true\" or \"false\", got " + profile[NODE_USE_ARN_REGION_INI_NAME] + ".");
    },
    default: false,
};
export var NODE_DISABLE_MULTIREGION_ACCESS_POINT_CONFIG_OPTIONS = {
    environmentVariableSelector: function (env) {
        if (!Object.prototype.hasOwnProperty.call(env, NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME))
            return undefined;
        if (env[NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME] === "true")
            return true;
        if (env[NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME] === "false")
            return false;
        throw new Error("Cannot load env " + NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME + ". Expected \"true\" or \"false\", got " + env[NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME] + ".");
    },
    configFileSelector: function (profile) {
        if (!Object.prototype.hasOwnProperty.call(profile, NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME))
            return undefined;
        if (profile[NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME] === "true")
            return true;
        if (profile[NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME] === "false")
            return false;
        throw new Error("Cannot load shared config entry " + NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME + ". Expected \"true\" or \"false\", got " + profile[NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME] + ".");
    },
    default: false,
};
