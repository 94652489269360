import { __asyncGenerator, __await, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { ListObjectsV2Command, } from "../commands/ListObjectsV2Command";
import { S3 } from "../S3";
import { S3Client } from "../S3Client";
var makePagedClientRequest = function (client, input) {
    var args = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, client.send.apply(client, __spreadArray([new ListObjectsV2Command(input)], __read(args)))];
                case 1: return [2, _a.sent()];
            }
        });
    });
};
var makePagedRequest = function (client, input) {
    var args = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, client.listObjectsV2.apply(client, __spreadArray([input], __read(args)))];
                case 1: return [2, _a.sent()];
            }
        });
    });
};
export function paginateListObjectsV2(config, input) {
    var additionalArguments = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        additionalArguments[_i - 2] = arguments[_i];
    }
    return __asyncGenerator(this, arguments, function paginateListObjectsV2_1() {
        var token, hasNext, page;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = config.startingToken || undefined;
                    hasNext = true;
                    _a.label = 1;
                case 1:
                    if (!hasNext) return [3, 9];
                    input.ContinuationToken = token;
                    input["MaxKeys"] = config.pageSize;
                    if (!(config.client instanceof S3)) return [3, 3];
                    return [4, __await(makePagedRequest.apply(void 0, __spreadArray([config.client, input], __read(additionalArguments))))];
                case 2:
                    page = _a.sent();
                    return [3, 6];
                case 3:
                    if (!(config.client instanceof S3Client)) return [3, 5];
                    return [4, __await(makePagedClientRequest.apply(void 0, __spreadArray([config.client, input], __read(additionalArguments))))];
                case 4:
                    page = _a.sent();
                    return [3, 6];
                case 5: throw new Error("Invalid client, expected S3 | S3Client");
                case 6: return [4, __await(page)];
                case 7: return [4, _a.sent()];
                case 8:
                    _a.sent();
                    token = page.NextContinuationToken;
                    hasNext = !!token;
                    return [3, 1];
                case 9: return [4, __await(undefined)];
                case 10: return [2, _a.sent()];
            }
        });
    });
}
