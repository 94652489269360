import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { parse as parseArn, validate as validateArn } from "@aws-sdk/util-arn-parser";
import { bucketHostname } from "./bucketHostname";
import { getPseudoRegion } from "./bucketHostnameUtils";
export var bucketEndpointMiddleware = function (options) { return function (next, context) { return function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var bucketName, replaceBucketInPath, request, bucketArn, clientRegion, _a, _b, partition, _c, signingRegion, useArnRegion, _d, hostname, bucketEndpoint, modifiedSigningRegion, signingService, _e, clientRegion, _f, _g, hostname, bucketEndpoint;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                bucketName = args.input.Bucket;
                replaceBucketInPath = options.bucketEndpoint;
                request = args.request;
                if (!HttpRequest.isInstance(request)) return [3, 9];
                if (!options.bucketEndpoint) return [3, 1];
                request.hostname = bucketName;
                return [3, 8];
            case 1:
                if (!validateArn(bucketName)) return [3, 6];
                bucketArn = parseArn(bucketName);
                _a = getPseudoRegion;
                return [4, options.region()];
            case 2:
                clientRegion = _a.apply(void 0, [_j.sent()]);
                return [4, options.regionInfoProvider(clientRegion)];
            case 3:
                _b = (_j.sent()) || {}, partition = _b.partition, _c = _b.signingRegion, signingRegion = _c === void 0 ? clientRegion : _c;
                return [4, options.useArnRegion()];
            case 4:
                useArnRegion = _j.sent();
                _e = bucketHostname;
                _h = {
                    bucketName: bucketArn,
                    baseHostname: request.hostname,
                    accelerateEndpoint: options.useAccelerateEndpoint,
                    dualstackEndpoint: options.useDualstackEndpoint,
                    pathStyleEndpoint: options.forcePathStyle,
                    tlsCompatible: request.protocol === "https:",
                    useArnRegion: useArnRegion,
                    clientPartition: partition,
                    clientSigningRegion: signingRegion,
                    clientRegion: clientRegion,
                    isCustomEndpoint: options.isCustomEndpoint
                };
                return [4, options.disableMultiregionAccessPoints()];
            case 5:
                _d = _e.apply(void 0, [(_h.disableMultiregionAccessPoints = _j.sent(),
                        _h)]), hostname = _d.hostname, bucketEndpoint = _d.bucketEndpoint, modifiedSigningRegion = _d.signingRegion, signingService = _d.signingService;
                if (modifiedSigningRegion && modifiedSigningRegion !== signingRegion) {
                    context["signing_region"] = modifiedSigningRegion;
                }
                if (signingService && signingService !== "s3") {
                    context["signing_service"] = signingService;
                }
                request.hostname = hostname;
                replaceBucketInPath = bucketEndpoint;
                return [3, 8];
            case 6:
                _f = getPseudoRegion;
                return [4, options.region()];
            case 7:
                clientRegion = _f.apply(void 0, [_j.sent()]);
                _g = bucketHostname({
                    bucketName: bucketName,
                    clientRegion: clientRegion,
                    baseHostname: request.hostname,
                    accelerateEndpoint: options.useAccelerateEndpoint,
                    dualstackEndpoint: options.useDualstackEndpoint,
                    pathStyleEndpoint: options.forcePathStyle,
                    tlsCompatible: request.protocol === "https:",
                    isCustomEndpoint: options.isCustomEndpoint,
                }), hostname = _g.hostname, bucketEndpoint = _g.bucketEndpoint;
                request.hostname = hostname;
                replaceBucketInPath = bucketEndpoint;
                _j.label = 8;
            case 8:
                if (replaceBucketInPath) {
                    request.path = request.path.replace(/^(\/)?[^\/]+/, "");
                    if (request.path === "") {
                        request.path = "/";
                    }
                }
                _j.label = 9;
            case 9: return [2, next(__assign(__assign({}, args), { request: request }))];
        }
    });
}); }; }; };
export var bucketEndpointMiddlewareOptions = {
    tags: ["BUCKET_ENDPOINT"],
    name: "bucketEndpointMiddleware",
    relation: "before",
    toMiddleware: "hostHeaderMiddleware",
    override: true,
};
export var getBucketEndpointPlugin = function (options) { return ({
    applyToStack: function (clientStack) {
        clientStack.addRelativeTo(bucketEndpointMiddleware(options), bucketEndpointMiddlewareOptions);
    },
}); };
