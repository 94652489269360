import { __extends } from "tslib";
import { getApplyMd5BodyChecksumPlugin } from "@aws-sdk/middleware-apply-body-checksum";
import { getBucketEndpointPlugin } from "@aws-sdk/middleware-bucket-endpoint";
import { getSerdePlugin } from "@aws-sdk/middleware-serde";
import { Command as $Command } from "@aws-sdk/smithy-client";
import { PutBucketReplicationRequest } from "../models/models_0";
import { deserializeAws_restXmlPutBucketReplicationCommand, serializeAws_restXmlPutBucketReplicationCommand, } from "../protocols/Aws_restXml";
var PutBucketReplicationCommand = (function (_super) {
    __extends(PutBucketReplicationCommand, _super);
    function PutBucketReplicationCommand(input) {
        var _this = _super.call(this) || this;
        _this.input = input;
        return _this;
    }
    PutBucketReplicationCommand.prototype.resolveMiddleware = function (clientStack, configuration, options) {
        this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
        this.middlewareStack.use(getBucketEndpointPlugin(configuration));
        this.middlewareStack.use(getApplyMd5BodyChecksumPlugin(configuration));
        var stack = clientStack.concat(this.middlewareStack);
        var logger = configuration.logger;
        var clientName = "S3Client";
        var commandName = "PutBucketReplicationCommand";
        var handlerExecutionContext = {
            logger: logger,
            clientName: clientName,
            commandName: commandName,
            inputFilterSensitiveLog: PutBucketReplicationRequest.filterSensitiveLog,
            outputFilterSensitiveLog: function (output) { return output; },
        };
        var requestHandler = configuration.requestHandler;
        return stack.resolve(function (request) {
            return requestHandler.handle(request.request, options || {});
        }, handlerExecutionContext);
    };
    PutBucketReplicationCommand.prototype.serialize = function (input, context) {
        return serializeAws_restXmlPutBucketReplicationCommand(input, context);
    };
    PutBucketReplicationCommand.prototype.deserialize = function (output, context) {
        return deserializeAws_restXmlPutBucketReplicationCommand(output, context);
    };
    return PutBucketReplicationCommand;
}($Command));
export { PutBucketReplicationCommand };
