import { __asyncGenerator, __await, __generator } from "tslib";
export function getChunkBuffer(data, partSize) {
    return __asyncGenerator(this, arguments, function getChunkBuffer_1() {
        var partNumber, startByte, endByte;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    partNumber = 1;
                    startByte = 0;
                    endByte = partSize;
                    _a.label = 1;
                case 1:
                    if (!(endByte < data.byteLength)) return [3, 4];
                    return [4, __await({
                            partNumber: partNumber,
                            data: data.slice(startByte, endByte),
                        })];
                case 2: return [4, _a.sent()];
                case 3:
                    _a.sent();
                    partNumber += 1;
                    startByte = endByte;
                    endByte = startByte + partSize;
                    return [3, 1];
                case 4: return [4, __await({
                        partNumber: partNumber,
                        data: data.slice(startByte),
                        lastPart: true,
                    })];
                case 5: return [4, _a.sent()];
                case 6:
                    _a.sent();
                    return [2];
            }
        });
    });
}
