import { __assign, __awaiter, __generator } from "tslib";
import { checkExceptions, createWaiter, WaiterState } from "@aws-sdk/util-waiter";
import { HeadBucketCommand } from "../commands/HeadBucketCommand";
var checkState = function (client, input) { return __awaiter(void 0, void 0, void 0, function () {
    var reason, result, exception_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4, client.send(new HeadBucketCommand(input))];
            case 1:
                result = _a.sent();
                reason = result;
                return [3, 3];
            case 2:
                exception_1 = _a.sent();
                reason = exception_1;
                if (exception_1.name && exception_1.name == "NotFound") {
                    return [2, { state: WaiterState.SUCCESS, reason: reason }];
                }
                return [3, 3];
            case 3: return [2, { state: WaiterState.RETRY, reason: reason }];
        }
    });
}); };
export var waitForBucketNotExists = function (params, input) { return __awaiter(void 0, void 0, void 0, function () {
    var serviceDefaults;
    return __generator(this, function (_a) {
        serviceDefaults = { minDelay: 5, maxDelay: 120 };
        return [2, createWaiter(__assign(__assign({}, serviceDefaults), params), input, checkState)];
    });
}); };
export var waitUntilBucketNotExists = function (params, input) { return __awaiter(void 0, void 0, void 0, function () {
    var serviceDefaults, result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                serviceDefaults = { minDelay: 5, maxDelay: 120 };
                return [4, createWaiter(__assign(__assign({}, serviceDefaults), params), input, checkState)];
            case 1:
                result = _a.sent();
                return [2, checkExceptions(result)];
        }
    });
}); };
