import { __asyncGenerator, __await, __generator } from "tslib";
import { Buffer } from "buffer";
export function getDataReadableStream(data) {
    return __asyncGenerator(this, arguments, function getDataReadableStream_1() {
        var reader, _a, done, value, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    reader = data.getReader();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 9, 10, 11]);
                    _b.label = 2;
                case 2:
                    if (!true) return [3, 8];
                    return [4, __await(reader.read())];
                case 3:
                    _a = _b.sent(), done = _a.done, value = _a.value;
                    if (!done) return [3, 5];
                    return [4, __await(void 0)];
                case 4: return [2, _b.sent()];
                case 5: return [4, __await(Buffer.from(value))];
                case 6: return [4, _b.sent()];
                case 7:
                    _b.sent();
                    return [3, 2];
                case 8: return [3, 11];
                case 9:
                    e_1 = _b.sent();
                    throw e_1;
                case 10:
                    reader.releaseLock();
                    return [7];
                case 11: return [2];
            }
        });
    });
}
