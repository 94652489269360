import { __assign, __awaiter, __generator, __values } from "tslib";
import { isArrayBuffer } from "@aws-sdk/is-array-buffer";
import { HttpRequest } from "@aws-sdk/protocol-http";
export var applyMd5BodyChecksumMiddleware = function (options) {
    return function (next) {
        return function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var request, body, headers, digest, hash, _a, _b, _c, _d, _e;
            var _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        request = args.request;
                        if (!HttpRequest.isInstance(request)) return [3, 2];
                        body = request.body, headers = request.headers;
                        if (!!hasHeader("content-md5", headers)) return [3, 2];
                        digest = void 0;
                        if (body === undefined || typeof body === "string" || ArrayBuffer.isView(body) || isArrayBuffer(body)) {
                            hash = new options.md5();
                            hash.update(body || "");
                            digest = hash.digest();
                        }
                        else {
                            digest = options.streamHasher(options.md5, body);
                        }
                        _a = [__assign({}, request)];
                        _f = {};
                        _b = [__assign({}, headers)];
                        _g = {};
                        _c = "content-md5";
                        _e = (_d = options).base64Encoder;
                        return [4, digest];
                    case 1:
                        request = __assign.apply(void 0, _a.concat([(_f.headers = __assign.apply(void 0, _b.concat([(_g[_c] = _e.apply(_d, [_h.sent()]), _g)])), _f)]));
                        _h.label = 2;
                    case 2: return [2, next(__assign(__assign({}, args), { request: request }))];
                }
            });
        }); };
    };
};
export var applyMd5BodyChecksumMiddlewareOptions = {
    name: "applyMd5BodyChecksumMiddleware",
    step: "build",
    tags: ["SET_CONTENT_MD5", "BODY_CHECKSUM"],
    override: true,
};
export var getApplyMd5BodyChecksumPlugin = function (config) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(applyMd5BodyChecksumMiddleware(config), applyMd5BodyChecksumMiddlewareOptions);
    },
}); };
var hasHeader = function (soughtHeader, headers) {
    var e_1, _a;
    soughtHeader = soughtHeader.toLowerCase();
    try {
        for (var _b = __values(Object.keys(headers)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var headerName = _c.value;
            if (soughtHeader === headerName.toLowerCase()) {
                return true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
};
