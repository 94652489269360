import { __assign, __read } from "tslib";
import { DOT_PATTERN, getArnResources, getPseudoRegion, getSuffix, getSuffixForArnEndpoint, isBucketNameOptions, isDnsCompatibleBucketName, isFipsRegion, validateAccountId, validateArnEndpointOptions, validateCustomEndpoint, validateDNSHostLabel, validateMrapAlias, validateNoDualstack, validateNoFIPS, validateOutpostService, validatePartition, validateRegion, validateRegionalClient, validateS3Service, validateService, } from "./bucketHostnameUtils";
export var bucketHostname = function (options) {
    validateCustomEndpoint(options);
    return isBucketNameOptions(options)
        ?
            getEndpointFromBucketName(options)
        :
            getEndpointFromArn(options);
};
var getEndpointFromBucketName = function (_a) {
    var _b = _a.accelerateEndpoint, accelerateEndpoint = _b === void 0 ? false : _b, region = _a.clientRegion, baseHostname = _a.baseHostname, bucketName = _a.bucketName, _c = _a.dualstackEndpoint, dualstackEndpoint = _c === void 0 ? false : _c, _d = _a.pathStyleEndpoint, pathStyleEndpoint = _d === void 0 ? false : _d, _e = _a.tlsCompatible, tlsCompatible = _e === void 0 ? true : _e, _f = _a.isCustomEndpoint, isCustomEndpoint = _f === void 0 ? false : _f;
    var _g = __read(isCustomEndpoint ? [region, baseHostname] : getSuffix(baseHostname), 2), clientRegion = _g[0], hostnameSuffix = _g[1];
    if (pathStyleEndpoint || !isDnsCompatibleBucketName(bucketName) || (tlsCompatible && DOT_PATTERN.test(bucketName))) {
        return {
            bucketEndpoint: false,
            hostname: dualstackEndpoint ? "s3.dualstack." + clientRegion + "." + hostnameSuffix : baseHostname,
        };
    }
    if (accelerateEndpoint) {
        baseHostname = "s3-accelerate" + (dualstackEndpoint ? ".dualstack" : "") + "." + hostnameSuffix;
    }
    else if (dualstackEndpoint) {
        baseHostname = "s3.dualstack." + clientRegion + "." + hostnameSuffix;
    }
    return {
        bucketEndpoint: true,
        hostname: bucketName + "." + baseHostname,
    };
};
var getEndpointFromArn = function (options) {
    var isCustomEndpoint = options.isCustomEndpoint, baseHostname = options.baseHostname, clientRegion = options.clientRegion;
    var hostnameSuffix = isCustomEndpoint ? baseHostname : getSuffixForArnEndpoint(baseHostname)[1];
    var pathStyleEndpoint = options.pathStyleEndpoint, _a = options.accelerateEndpoint, accelerateEndpoint = _a === void 0 ? false : _a, _b = options.tlsCompatible, tlsCompatible = _b === void 0 ? true : _b, bucketName = options.bucketName, _c = options.clientPartition, clientPartition = _c === void 0 ? "aws" : _c;
    validateArnEndpointOptions({ pathStyleEndpoint: pathStyleEndpoint, accelerateEndpoint: accelerateEndpoint, tlsCompatible: tlsCompatible });
    var service = bucketName.service, partition = bucketName.partition, accountId = bucketName.accountId, region = bucketName.region, resource = bucketName.resource;
    validateService(service);
    validatePartition(partition, { clientPartition: clientPartition });
    validateAccountId(accountId);
    var _d = getArnResources(resource), accesspointName = _d.accesspointName, outpostId = _d.outpostId;
    if (service === "s3-object-lambda") {
        return getEndpointFromObjectLambdaArn(__assign(__assign({}, options), { tlsCompatible: tlsCompatible, bucketName: bucketName, accesspointName: accesspointName, hostnameSuffix: hostnameSuffix }));
    }
    if (region === "") {
        return getEndpointFromMRAPArn(__assign(__assign({}, options), { clientRegion: clientRegion, mrapAlias: accesspointName, hostnameSuffix: hostnameSuffix }));
    }
    if (outpostId) {
        return getEndpointFromOutpostArn(__assign(__assign({}, options), { clientRegion: clientRegion, outpostId: outpostId, accesspointName: accesspointName, hostnameSuffix: hostnameSuffix }));
    }
    return getEndpointFromAccessPointArn(__assign(__assign({}, options), { clientRegion: clientRegion, accesspointName: accesspointName, hostnameSuffix: hostnameSuffix }));
};
var getEndpointFromObjectLambdaArn = function (_a) {
    var _b = _a.dualstackEndpoint, dualstackEndpoint = _b === void 0 ? false : _b, _c = _a.tlsCompatible, tlsCompatible = _c === void 0 ? true : _c, useArnRegion = _a.useArnRegion, clientRegion = _a.clientRegion, _d = _a.clientSigningRegion, clientSigningRegion = _d === void 0 ? clientRegion : _d, accesspointName = _a.accesspointName, bucketName = _a.bucketName, hostnameSuffix = _a.hostnameSuffix;
    var accountId = bucketName.accountId, region = bucketName.region, service = bucketName.service;
    validateRegionalClient(clientRegion);
    validateRegion(region, { useArnRegion: useArnRegion, clientRegion: clientRegion, clientSigningRegion: clientSigningRegion, allowFipsRegion: true });
    validateNoDualstack(dualstackEndpoint);
    var DNSHostLabel = accesspointName + "-" + accountId;
    validateDNSHostLabel(DNSHostLabel, { tlsCompatible: tlsCompatible });
    var endpointRegion = useArnRegion ? region : clientRegion;
    var signingRegion = useArnRegion ? region : clientSigningRegion;
    return {
        bucketEndpoint: true,
        hostname: DNSHostLabel + "." + service + (isFipsRegion(clientRegion) ? "-fips" : "") + "." + getPseudoRegion(endpointRegion) + "." + hostnameSuffix,
        signingRegion: signingRegion,
        signingService: service,
    };
};
var getEndpointFromMRAPArn = function (_a) {
    var disableMultiregionAccessPoints = _a.disableMultiregionAccessPoints, _b = _a.dualstackEndpoint, dualstackEndpoint = _b === void 0 ? false : _b, isCustomEndpoint = _a.isCustomEndpoint, mrapAlias = _a.mrapAlias, hostnameSuffix = _a.hostnameSuffix;
    if (disableMultiregionAccessPoints === true) {
        throw new Error("SDK is attempting to use a MRAP ARN. Please enable to feature.");
    }
    validateMrapAlias(mrapAlias);
    validateNoDualstack(dualstackEndpoint);
    return {
        bucketEndpoint: true,
        hostname: "" + mrapAlias + (isCustomEndpoint ? "" : ".accesspoint.s3-global") + "." + hostnameSuffix,
        signingRegion: "*",
    };
};
var getEndpointFromOutpostArn = function (_a) {
    var useArnRegion = _a.useArnRegion, clientRegion = _a.clientRegion, _b = _a.clientSigningRegion, clientSigningRegion = _b === void 0 ? clientRegion : _b, bucketName = _a.bucketName, outpostId = _a.outpostId, _c = _a.dualstackEndpoint, dualstackEndpoint = _c === void 0 ? false : _c, _d = _a.tlsCompatible, tlsCompatible = _d === void 0 ? true : _d, accesspointName = _a.accesspointName, isCustomEndpoint = _a.isCustomEndpoint, hostnameSuffix = _a.hostnameSuffix;
    validateRegionalClient(clientRegion);
    validateRegion(bucketName.region, { useArnRegion: useArnRegion, clientRegion: clientRegion, clientSigningRegion: clientSigningRegion });
    var DNSHostLabel = accesspointName + "-" + bucketName.accountId;
    validateDNSHostLabel(DNSHostLabel, { tlsCompatible: tlsCompatible });
    var endpointRegion = useArnRegion ? bucketName.region : clientRegion;
    var signingRegion = useArnRegion ? bucketName.region : clientSigningRegion;
    validateOutpostService(bucketName.service);
    validateDNSHostLabel(outpostId, { tlsCompatible: tlsCompatible });
    validateNoDualstack(dualstackEndpoint);
    validateNoFIPS(endpointRegion);
    var hostnamePrefix = DNSHostLabel + "." + outpostId;
    return {
        bucketEndpoint: true,
        hostname: "" + hostnamePrefix + (isCustomEndpoint ? "" : ".s3-outposts." + endpointRegion) + "." + hostnameSuffix,
        signingRegion: signingRegion,
        signingService: "s3-outposts",
    };
};
var getEndpointFromAccessPointArn = function (_a) {
    var useArnRegion = _a.useArnRegion, clientRegion = _a.clientRegion, _b = _a.clientSigningRegion, clientSigningRegion = _b === void 0 ? clientRegion : _b, bucketName = _a.bucketName, _c = _a.dualstackEndpoint, dualstackEndpoint = _c === void 0 ? false : _c, _d = _a.tlsCompatible, tlsCompatible = _d === void 0 ? true : _d, accesspointName = _a.accesspointName, isCustomEndpoint = _a.isCustomEndpoint, hostnameSuffix = _a.hostnameSuffix;
    validateRegionalClient(clientRegion);
    validateRegion(bucketName.region, { useArnRegion: useArnRegion, clientRegion: clientRegion, clientSigningRegion: clientSigningRegion, allowFipsRegion: true });
    var hostnamePrefix = accesspointName + "-" + bucketName.accountId;
    validateDNSHostLabel(hostnamePrefix, { tlsCompatible: tlsCompatible });
    var endpointRegion = useArnRegion ? bucketName.region : clientRegion;
    var signingRegion = useArnRegion ? bucketName.region : clientSigningRegion;
    validateS3Service(bucketName.service);
    return {
        bucketEndpoint: true,
        hostname: "" + hostnamePrefix + (isCustomEndpoint
            ? ""
            : ".s3-accesspoint" + (isFipsRegion(clientRegion) ? "-fips" : "") + (dualstackEndpoint ? ".dualstack" : "") + "." + getPseudoRegion(endpointRegion)) + "." + hostnameSuffix,
        signingRegion: signingRegion,
    };
};
