import { __extends } from "tslib";
import { getBucketEndpointPlugin } from "@aws-sdk/middleware-bucket-endpoint";
import { getSerdePlugin } from "@aws-sdk/middleware-serde";
import { Command as $Command } from "@aws-sdk/smithy-client";
import { DeleteBucketOwnershipControlsRequest } from "../models/models_0";
import { deserializeAws_restXmlDeleteBucketOwnershipControlsCommand, serializeAws_restXmlDeleteBucketOwnershipControlsCommand, } from "../protocols/Aws_restXml";
var DeleteBucketOwnershipControlsCommand = (function (_super) {
    __extends(DeleteBucketOwnershipControlsCommand, _super);
    function DeleteBucketOwnershipControlsCommand(input) {
        var _this = _super.call(this) || this;
        _this.input = input;
        return _this;
    }
    DeleteBucketOwnershipControlsCommand.prototype.resolveMiddleware = function (clientStack, configuration, options) {
        this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
        this.middlewareStack.use(getBucketEndpointPlugin(configuration));
        var stack = clientStack.concat(this.middlewareStack);
        var logger = configuration.logger;
        var clientName = "S3Client";
        var commandName = "DeleteBucketOwnershipControlsCommand";
        var handlerExecutionContext = {
            logger: logger,
            clientName: clientName,
            commandName: commandName,
            inputFilterSensitiveLog: DeleteBucketOwnershipControlsRequest.filterSensitiveLog,
            outputFilterSensitiveLog: function (output) { return output; },
        };
        var requestHandler = configuration.requestHandler;
        return stack.resolve(function (request) {
            return requestHandler.handle(request.request, options || {});
        }, handlerExecutionContext);
    };
    DeleteBucketOwnershipControlsCommand.prototype.serialize = function (input, context) {
        return serializeAws_restXmlDeleteBucketOwnershipControlsCommand(input, context);
    };
    DeleteBucketOwnershipControlsCommand.prototype.deserialize = function (output, context) {
        return deserializeAws_restXmlDeleteBucketOwnershipControlsCommand(output, context);
    };
    return DeleteBucketOwnershipControlsCommand;
}($Command));
export { DeleteBucketOwnershipControlsCommand };
