import { __extends } from "tslib";
import { getBucketEndpointPlugin } from "@aws-sdk/middleware-bucket-endpoint";
import { getSerdePlugin } from "@aws-sdk/middleware-serde";
import { Command as $Command } from "@aws-sdk/smithy-client";
import { GetBucketRequestPaymentOutput, GetBucketRequestPaymentRequest } from "../models/models_0";
import { deserializeAws_restXmlGetBucketRequestPaymentCommand, serializeAws_restXmlGetBucketRequestPaymentCommand, } from "../protocols/Aws_restXml";
var GetBucketRequestPaymentCommand = (function (_super) {
    __extends(GetBucketRequestPaymentCommand, _super);
    function GetBucketRequestPaymentCommand(input) {
        var _this = _super.call(this) || this;
        _this.input = input;
        return _this;
    }
    GetBucketRequestPaymentCommand.prototype.resolveMiddleware = function (clientStack, configuration, options) {
        this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
        this.middlewareStack.use(getBucketEndpointPlugin(configuration));
        var stack = clientStack.concat(this.middlewareStack);
        var logger = configuration.logger;
        var clientName = "S3Client";
        var commandName = "GetBucketRequestPaymentCommand";
        var handlerExecutionContext = {
            logger: logger,
            clientName: clientName,
            commandName: commandName,
            inputFilterSensitiveLog: GetBucketRequestPaymentRequest.filterSensitiveLog,
            outputFilterSensitiveLog: GetBucketRequestPaymentOutput.filterSensitiveLog,
        };
        var requestHandler = configuration.requestHandler;
        return stack.resolve(function (request) {
            return requestHandler.handle(request.request, options || {});
        }, handlerExecutionContext);
    };
    GetBucketRequestPaymentCommand.prototype.serialize = function (input, context) {
        return serializeAws_restXmlGetBucketRequestPaymentCommand(input, context);
    };
    GetBucketRequestPaymentCommand.prototype.deserialize = function (output, context) {
        return deserializeAws_restXmlGetBucketRequestPaymentCommand(output, context);
    };
    return GetBucketRequestPaymentCommand;
}($Command));
export { GetBucketRequestPaymentCommand };
